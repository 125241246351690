// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;
var lualBodyClasses = ["christmas-appeal-2021", "christmas-appeal-2022", "PostSystemCategory_christmas-appeal-style"];


// Carousel + Home Intro - Move down the homepage
$("body.homepage:not(.subsite) .carousel").insertAfter(".homeFeaturesWrapper");
$(".homeIntroWrapper").insertAfter(".homeFeaturesWrapper");

// Search container - Placeholder
$('#siteSearch').prop("placeholder", "What are you looking for?");

// Remove click from homeboxes
$(".homeFeatures .homeFeature:not(.homeBox1").off("click");
$(".homeFeatures .homeFeature [class*='image-link']").off("click");

// Logo - Pebble
$("<span class='logo-pebble' />").insertAfter(".mainLogo");

$("#bodyForm").find("> ul, > h1, > h2, > h3, > h4, > h5, > h6")
              .wrapAll("<div class='bespokeSidebar associated' />");
$(".associated.bespokeSidebar").prependTo(".post .contentBlock .postAside .content")

// Footer logos
$(".footerBox[class*='ogos']").insertAfter(".pageFooterWrapper");

// Post no banner
$(".homeFeatures .homeFeature:not(:has([class*='image-link']))").addClass("homeFeatureNoBanner");
$(".feedItem:not(:has([class*='image-link']))").addClass("feedItemNoBanner");
$(".listedPost:not(:has([class*='image-link']))").addClass("listedPostNoBanner");

// Home Features - View all button
$(".homeFeatures .homeFeature").each(function() {
  $(this).find(".homeFeatureDetailsWrapper ul").append("<li><a>View All</a>");
});

$(document).ready(function () {
  $(".headerTextSubsite").appendTo(".headerWrapperSubsite");
});